"use client";
import React, { useEffect, useLayoutEffect } from "react";
import { Navigation, Pagination, Parallax } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { gsap } from "gsap";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import slidesData from "../../data/Landing/heroslides.json";
import Lottie from "lottie-react";
import HZLogoAnimation from "../animation/HZ-Logo-Lottie.json";

function Header() {
  useLayoutEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo(".header", { y: 80 }, { y: 0 }, "+=0");
    tl.fromTo(
      ".header .slider",
      { opacity: 0, translateY: 80 },
      { opacity: 1, translateY: 0 },
      "-=0"
    );

    return () => tl.kill();
  }, []);

  console.clear();

  const swiperOptions = {
    modules: [Navigation, Parallax, Pagination],
    parallax: true,
    loop: true,
    delay: 0,
    spaceBetween: 0,
    speed: 600,
    opacity: 0,
    navigation: {
      nextEl: ".slider-contro",
      prevEl: ".slider-contro",
    },
    pagination: {
      el: ".swiper-pagination",
    },
  };
  function loadBackgroudImages() {
    let backgroudImages = document.querySelectorAll("[data-background]");

    if (backgroudImages.length > 0) {
      backgroudImages.forEach((element) => {
        let image = element.dataset.background;
        element.style.backgroundImage = `url('${image}')`;
      });
    }
  }
  useEffect(() => {
    loadBackgroudImages();
  }, []);

  console.clear();

  return (
    <section className="header pagetop" id="topofpage">
      <div
        className="slider iframe-slider"
        style={{
          top: "0px",
          height: "95vh",
          overflow: "hidden",
          cursor: "move",
        }}
      >
        <Swiper
          effect="Parallax"
          className="swiper-container parallax-slider"
          {...swiperOptions}
        >
          {slidesData.map((slide) => (
            <SwiperSlide key={slide.id}>
              <div className="bg-img valign" data-overlay-dark="1">
                <iframe
                  id="slider-iframe"
                  className="bg-img valign"
                  data-overlay-dark="1"
                  src={slide.backgroundImage}
                  title="hz-design-hats"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    width: "100vw",
                    height: "100vh",
                    position: "absolute",
                    opacity: 1,
                    zIndex: 0,
                  }}
                ></iframe>
                <div className="container" style={{ mixBlendMode: "none" }}>
                  <div className="caption text-left">
                    <h6
                      id="slide3-span"
                      className="mb-5 ml-10"
                      data-swiper-parallax="-2000"
                      style={{ fontWeight: "300", letterSpacing: "1px" }}
                    >
                      {slide.caption.h6}
                    </h6>
                    <h1 id="slide1-h1">
                      <span data-swiper-parallax="-2500">
                        {slide.caption.h1[0]}
                      </span>
                      <br />
                      <span data-swiper-parallax="-3500">
                        {slide.caption.h1[1]}
                      </span>
                    </h1>
                    <p
                      id="slide-p"
                      className="mt-20 mr-10 ml-10"
                      data-swiper-parallax="-4000"
                    >
                      {slide.caption.p[0]}
                      <br />
                      {slide.caption.p[1]}
                    </p>
                    <Link to={slide.caption.link}>
                      <Button
                        className="mt-20 mr-10 ml-10"
                        variant="outlined"
                        size="large"
                        sx={{
                          color: "yellowgreen",
                          background: "#00000080",
                          fontWeight: "400",
                          borderRadius: "4px",
                          border: "1px solid yellowgreen",
                          filter: "brightness(1)",
                          transition: "all 0.3s ease",
                          "&:hover": {
                            filter: "brightness(1.2)",
                            border: "1px solid yellowgreen",
                            borderRadius: "4px",
                            transition: "all 0.3s ease",
                          },
                        }}
                      >
                        {slide.caption.buttonText}
                      </Button>
                    </Link>
                  </div>
                  <div className="slider-mobile-thumbpath" />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {/* Swiper Controls */}
        <div className="slider-contro main-bg">
          <div className="swiper-button-prev swiper-nav-ctrl">
            <div>
              <span>Prev</span>
            </div>
          </div>
          <div className="ml-30 mr-30">
            <span>/</span>
          </div>
          <div className="swiper-button-next swiper-nav-ctrl">
            <div>
              <span>Next</span>
            </div>
          </div>

          <div className="shap-left-bottom">
            <svg
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-11 h-11"
            >
              <path
                d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                fill="#12152c"
              ></path>
            </svg>
          </div>
          <div className="shap-right-top">
            <svg
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-11 h-11"
            >
              <path
                d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                fill="#12152c"
              ></path>
            </svg>
          </div>
        </div>
        <div className="swiper-pagination"></div>
      </div>
      <div className="hz-lottie-container">
        <Lottie
          id="hz-logo-animation"
          animationData={HZLogoAnimation}
          loop={false}
          style={{
            position: "fixed",
          }}
        />
      </div>
    </section>
  );
}

export default Header;
console.clear();
