import React from "react";
import { Navigation, Pagination, Parallax } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Lottie from "lottie-react";
import SlideRightLottie from "../../../animation/Slide-Lottie2.json";
import { Link } from "react-router-dom";
import projectData from "../../../../data/projectarchives/anz6623Data.json";

function Story() {
  const { storyData } = projectData;

  return (
    <section className="section-padding">
      {storyData.sections.map((section, index) => (
        <div key={index} className="container-fluid rest mb-100">
          <div className="col-md-12">
            <div className="text-center mb-30">
              <h1>{section.title}</h1>
            </div>
          </div>
          <Swiper
            modules={[Navigation, Pagination, Parallax]}
            parallax
            spaceBetween={0}
            centeredSlides={true}
            loop={true}
            className="project2-swiper"
          >
            {section.slides.map((slide, slideIndex) => (
              <SwiperSlide key={slideIndex} style={{ marginRight: "0" }}>
                <div className="img">
                  <img src={slide} alt={`Slide ${slideIndex + 1}`} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <Lottie
            id="slide-lottie-img2"
            animationData={SlideRightLottie}
            loop={true}
            style={{ marginTop: "-25px" }}
          />
        </div>
      ))}
      <div  className="container-lg">
      <div className="gallery row sm-marg">
        {storyData.images.map((image, imageIndex) => (
          <div key={imageIndex} className="col-lg-6 items design brand">
            <div className="item mt-10">
              <div className="img">
                <img src={image} alt={`project-img ${imageIndex + 1}`} />
              </div>
            </div>
          </div>
        ))}
      </div>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="cont section-padding">
              {storyData.content.map((contentItem, contentIndex) => (
                <div key={contentIndex} className="row mb-30">
                  <div className="col-md-3 sm-mb30">
                    <h6>{contentItem.heading}</h6>
                  </div>
                  <div className="col-lg-6 col-md-9">
                    <div className="text">
                      <p>{contentItem.text}</p>
                      {contentItem.link && (
                        <Link
                          to={contentItem.link.url}
                          className="fz-28 fw-400 stroke next-archive-link mt-30"
                        >
                          {contentItem.link.text}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Story;

console.clear();
