"use client";
import { Helmet } from "react-helmet";
import Footer from "../components/default/Footer";
import ProgressScroll from "../components/default/ProgressScroll";
import Header from "../components/default/projects/project-indus-datm/Header";
//import Challenge from "../components/default/projects/project-indus-datm/Header";
import Next from "../components/default/projects/project-indus-datm/Next";
import Story from "../components/default/projects/project-indus-datm/Story";
import ArchiveHeaderTrans from "../components/default/ArchiveHeaderTrans";
import LoadingScreen from "../common/loader";

export default function ProjectDatm() {
  return (
    <>
      <Helmet>
        <title>Archives | Industrial Design</title>
        <link rel="icon" href="/dark/imgs/nav/favicon.ico" />
        <link rel="shortcut icon" href="/dark/imgs/nav/favicon.ico" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/plugins.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/style.css"
        />
      </Helmet>
      <body>
        <LoadingScreen />
        <ProgressScroll />
        <div id="smooth-wrapper">
          <div id="smooth-content">
            <main className="main-bg">
              <ArchiveHeaderTrans />
              <Header />
              {/* <Challenge /> */}
              <Story />
              <Next />
            </main>
            <Footer />
          </div>
        </div>
      </body>
    </>
  );
}
