import React, { useState, useRef, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import data from "../../data/services.json";
//import { Link } from "react-router-dom";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import NorthEastIcon from "@mui/icons-material/NorthEast";

function Services2() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startPos, setStartPos] = useState(0);
  const [translate, setTranslate] = useState(0);
  const slideCount = data.slice(0, 8).length;
  const slideWidth = 100; // Adjust as needed based on your CSS
  const carouselRef = useRef(null);

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
  });

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slideCount);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slideCount - 1 : prevSlide - 1
    );
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartPos(e.clientX);
    carouselRef.current.style.transition = "none";
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const currentPosition = e.clientX;
    const difference = currentPosition - startPos;
    setTranslate(difference);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    carouselRef.current.style.transition = "transform 0.5s ease-in-out";
    if (translate > 50) {
      prevSlide();
    } else if (translate < -50) {
      nextSlide();
    }
    setTranslate(0);
  };

  useEffect(() => {
    const handleTransitionEnd = () => {
      if (currentSlide === slideCount) {
        carouselRef.current.style.transition = "none";
        setCurrentSlide(0);
      } else if (currentSlide === -1) {
        carouselRef.current.style.transition = "none";
        setCurrentSlide(slideCount - 1);
      }
    };

    const carouselElement = carouselRef.current;
    carouselElement.addEventListener("transitionend", handleTransitionEnd);

    return () => {
      carouselElement.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, [currentSlide, slideCount]);

  useEffect(() => {
    if (currentSlide === slideCount || currentSlide === -1) {
      setTimeout(() => {
        carouselRef.current.style.transition = "none";
      }, 0);
    } else {
      carouselRef.current.style.transition = "transform 0.5s ease-in-out";
    }
  }, [currentSlide, slideCount]);

  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: false });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const variants = {
    hidden: {
      opacity: 0,
      y: 50,
      z: -50,
      rotateX: -45,
      scale: 0.95,
      transition: {
        duration: 0.8,
        delay: 0.5,
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      z: 0,
      rotateX: 0,
      scale: 1,
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <section
      className="services section-padding"
      {...handlers}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <div className="container">
        <div className="sec-head mb-80">
          <h6 className="sub-title main-color mb-25">Expertise</h6>
          <div className="bord pt-25 bord-thin-top d-flex align-items-center">
            <motion.h2
              className="fw-600 hz-d-rotate"
              ref={ref}
              initial="hidden"
              animate={controls}
              variants={variants}
            >
              <motion.span className="hz-rotate-text" variants={variants}>
                <span className="fw-200">Professional Capabilities</span>
              </motion.span>
            </motion.h2>
            <div className="ml-auto">
              <div className="swiper-arrow-control">
                <div
                  className="swiper-button-prev"
                  onClick={prevSlide}
                  style={{ position: "relative", marginRight: "1rem" }}
                >
                  <span className="ti-arrow-left"></span>
                </div>
                <div
                  className="swiper-button-next"
                  onClick={nextSlide}
                  style={{ position: "relative" }}
                >
                  <span className="ti-arrow-right"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="serv-carousel">
          <div
            className="carousel-container"
            ref={carouselRef}
            style={{
              transform: `translateX(${
                -currentSlide * slideWidth + translate / 10
              }%)`,
            }}
          >
            <div className="carousel-wrapper">
              {data.slice(0, 8).map((item, i) => (
                <div key={i} className="carousel-slide">
                  <div className="item-box radius-15">
                    <div
                      className="img icon-img-100 mb-10 opacity-8"
                      style={{ marginLeft: "-1.5rem" }}
                    >
                      <img
                        src={item.img}
                        alt=""
                        style={{ filter: "invert(1)" }}
                      />
                    </div>
                    <h5 className="mb-15">{item.title}</h5>
                    <p>{item.desc}</p>
                    <a href={item.link} className="rmore mt-30">
                      <span className="sub-title mr-10">Read More</span>
                      {/* <img
                        src="/dark/assets/imgs/arrow-right.png"
                        alt=""
                        className="icon-img-20 ml-5"
                      /> */}
                      <NorthEastIcon />
                    </a>
                  </div>
                </div>
              ))}
              {/* Duplicate slides for infinite looping */}
              {data.slice(0, 8).map((item, i) => (
                <div key={`duplicate-${i}`} className="carousel-slide">
                  <div className="item-box radius-15">
                    <div
                      className="img icon-img-100 mb-10 opacity-8"
                      style={{ marginLeft: "-1.5rem" }}
                    >
                      <img
                        src={item.img}
                        alt=""
                        style={{ filter: "invert(1)" }}
                      />
                    </div>
                    <h5 className="mb-15">{item.title}</h5>
                    <p>{item.desc}</p>
                    <a href={item.link} className="rmore mt-30">
                      <span className="sub-title">Read More</span>
                      <img
                        src="/dark/assets/imgs/arrow-right.png"
                        alt=""
                        className="icon-img-20 ml-5"
                      />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services2;
