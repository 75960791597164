import React from "react";
import { Navigation, Pagination, Parallax } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Lottie from "lottie-react";
import SlideRightLottie from "../../../animation/Slide-Lottie2.json";

function Story() {
  return (
    <section className="section-padding">
      <div className="container-fluid rest mb-100">
        <div className="col-md-12">
          <div className="text-center mb-30">
            <h1>{/* Heading */}</h1>
            <p>Version 2 | Wordpress CMS | Custom Theme developed with PHP</p>
          </div>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Parallax]}
          //navigation
          //pagination={{ clickable: true }}
          parallax
          spaceBetween={0}
          centeredSlides={true}
          loop={true}
          className="project2-swiper"
          //style={{ overflow: "visible", maxWidth: "640px" }}
        >
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                loading="lazy"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-1.webm"
                alt="Slide 1"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                loading="lazy"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-2.webm"
                alt="Slide 2"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                loading="lazy"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-3.webm"
                alt="Slide 3"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                loading="lazy"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-1.webm"
                alt="Slide 1"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                loading="lazy"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-2.webm"
                alt="Slide 2"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                loading="lazy"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-3.webm"
                alt="Slide 3"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                loading="lazy"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-1.webm"
                alt="Slide 1"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                loading="lazy"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-2.webm"
                alt="Slide 2"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                loading="lazy"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-3.webm"
                alt="Slide 3"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                loading="lazy"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-1.webm"
                alt="Slide 1"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                loading="lazy"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-2.webm"
                alt="Slide 2"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
            <div className="slide-video-wrapper">
              <video
                className="video"
                loading="lazy"
                src="/dark/assets/imgs/works/projects/web/proj-web-rsg-3.webm"
                alt="Slide 3"
                autoPlay
                loop
                muted
                width="100%"
                height="auto"
                //height={window.innerWidth > 768 ? "100%" : "auto"}
              />
            </div>
          </SwiperSlide>
        </Swiper>
        <Lottie
          id="slide-lottie-img2"
          animationData={SlideRightLottie}
          loop={true}
          style={{ marginTop: "-25px" }}
        />
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="cont section-padding">
              <div className="row">
                <div className="col-md-3 sm-mb30">
                  <h6>The Problem with Wordpress</h6>
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>
                      Before wordpress supported headless cms, it was extremely
                      vulnerable. The site was slow and not user friendly. As
                      the site is also intended to create brand dominance within
                      the vr xr industry, in corporating custom threejs content
                      was challenging. <br />
                      <br /> Innitialy, react was my framework of choice, as I
                      was fairly new to it, handling deployment and backend
                      enviroments had to be upgraded as well. And so, the site
                      was rebuilt on wordpress with a new design. The site was
                      built with a custom theme developed with PHP. The site was
                      also optimized for SEO and mobile responsiveness.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <p className="text-center mb-10">
                  Understand in Infrastructure to suit goals
                </p>
                <div className="container-fluid img-long-container rest mb-30">
                  <div
                    className="img img-long"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      src="/dark/assets/imgs/works/projects/web/rsgui/infra.webp"
                      alt="hero img"
                      style={{ maxWidth: "1920px" }}
                      loading="lazy"
                    />
                  </div>
                </div>
                <Lottie
                  id="slide-lottie-img1"
                  animationData={SlideRightLottie}
                  loop={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Story;
