import React from "react";
import { Navigation, Pagination, Parallax } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Lottie from "lottie-react";
import SlideRightLottie from "../../../animation/Slide-Lottie2.json";
import { Link } from "react-router-dom";

function Story2() {
  return (
    <section className="section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-3 sm-mb30">
                <h6>The Intrim Solution</h6>
              </div>
              <div className="col-lg-6 col-md-9">
                <div className="text">
                  <ul>
                    <li style={{ listStyle: "disc" }}>
                      {" "}
                      Developed with PHP to address hosting and server
                      constraints.
                    </li>
                    <br />
                    <li style={{ listStyle: "disc" }}>
                      {" "}
                      Upgrade security and performance with custom validation
                      and error handling.
                    </li>
                    <br />
                    <li style={{ listStyle: "disc" }}>
                      {" "}
                      Intergrate google analytics and tag manager for data
                      tracking and analysis.
                    </li>
                    <br />
                    <li style={{ listStyle: "disc" }}>
                      {" "}
                      Setup and configure Google Search Console for indexing and
                      search performance.
                    </li>
                    <br />
                    <li style={{ listStyle: "disc" }}>
                      {" "}
                      Setup goals and events tracking for user interaction and
                      conversion analysis.
                    </li>
                    <br />
                    <li style={{ listStyle: "disc" }}>
                      {" "}
                      Integrate SEMrush for SEO and performance monitoring.
                    </li>
                    <br />
                    <li style={{ listStyle: "disc" }}>
                      {" "}
                      Integrate Hotjar for user experience and behavior
                      analysis.
                    </li>
                    <br />
                    <li style={{ listStyle: "disc" }}>
                      {" "}
                      Link with social media platforms for content sharing and
                      engagement (LinkedIn, Youtube, Instagram).
                    </li>
                    <br />
                    <li style={{ listStyle: "disc" }}>
                      {" "}
                      Setup and configure Google My Business for local search
                      and business listing.
                    </li>
                    <br />
                    <li style={{ listStyle: "disc" }}>
                      {" "}
                      Setup and configure Google Ads for search and display
                      advertising.
                    </li>
                    <br />
                    <li style={{ listStyle: "disc" }}>
                      {" "}
                      Setup, configure and monitor Campaigns on LinkedIn.
                    </li>
                    <br />
                    <li style={{ listStyle: "disc" }}>
                      {" "}
                      Create a custom dashboard (Dashten) to assist with
                      managing and monitoring the digital eco-system.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="text-center mt-60 mb-10">
                <p>Version 3 | Custom PHP + React-Three-Fibre</p>
              </div>
            </div>
          </div>
          <div className="container-fluid rest">
            <Swiper
              modules={[Navigation, Pagination, Parallax]}
              parallax
              spaceBetween={0}
              centeredSlides={true}
              loop={true}
              className="project2-swiper"
              style={{ maxHeight: "500px" }}
            >
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  id="rsg-vid1"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <video
                    className="video"
                    src="/dark/assets/imgs/video/rsgweb-1460x1290.webm"
                    alt="Slide 1"
                    autoPlay
                    loop
                    muted
                    width="100%"
                    height="auto"
                    style={{
                      maxWidth: "1460px",
                      objectFit: "cover",
                      minHeight: "600px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/02.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/01.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/03.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/04.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/05.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/06.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/07.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/082.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/09.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/10.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/11.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/12.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/13.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/13.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/15.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/16.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/17.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/18.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/19.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/20.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/21.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/22.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/23.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/24.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/25.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/26.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/27.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/28.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="slider-video"
                style={{ marginRight: "0" }}
              >
                <div
                  className="slide-video-wrapper"
                  style={{ maxHeight: "500px", overflow: "hidden" }}
                >
                  <img
                    src="/dark/assets/imgs/works/projects/web/rsgui/v3/29.webp"
                    alt="v2 website img"
                    style={{
                      width: "auto",
                      objectFit: "contain",
                      minHeight: "500px",
                    }}
                  />
                </div>
              </SwiperSlide>
            </Swiper>
            <Lottie
              id="slide-lottie-img2"
              animationData={SlideRightLottie}
              loop={true}
              style={{ marginTop: "-25px" }}
            />
          </div>
        </div>
      </div>


      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="cont section-padding">
              <div className="row mb-30">
                <div className="col-md-3 sm-mb30">
                  <h6>{/* Heading */}</h6>
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>{/* description */}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 sm-mb30">
                  <h6>Next on the list</h6>
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>Customise Dashboard to assist with data collection process.</p>
                    <Link
                      to="/archives/websites/rsg"
                      className="fz-28 fw-400 stroke next-archive-link mt-30"
                    >
                      DashTen
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Story2;
