'use client';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

function Clients() {
  const swiperOptions = {
    speed: 500,
    loop: true,
    breakpoints: {
      // when window width is >= 640px
      390: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 10,
        centeredSlides: false,
      },
      500: {
        loop: true,
        slidesPerView: 2,
        spaceBetween: 20,
        centeredSlides: false,
      },
      // when window width is >= 768px
      700: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 30,
        centeredSlides: false,
      },
      // when window width is >= 1200px
      1000: {
        loop: true,
        slidesPerView: 5,
        spaceBetween: 30,
        centeredSlides: true,
      },
    },
  };
  return (
    <section className="clients-carso section-padding pt-0">
      <div className="container">
        <div className="sec-bottom mb-100">
          <div className="main-bg2 d-flex align-items-center">
            <h6 className="fz-14 fw-400">
              Fostering <span className="fw-600"> Collaborations</span>
            </h6>
          </div>
        </div>
        <div
          className="swiper5"
          data-carousel="swiper"
          data-items="5"
          data-loop="true"
          data-space="40"
        >
          <Swiper
            id="content-carousel-container-unq-clients"
            className="swiper-container"
            data-swiper="container"
            loop={true}
            {...swiperOptions}
            style={{ cursor: "w-resize", userSelect: "none" }}
          >
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100" style={{ width: "150px" }}>
                  <img src="/dark/assets/imgs/brands/01.webp" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100" style={{ width: "150px" }}>
                  <img src="/dark/assets/imgs/brands/02.webp" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100" style={{ width: "150px" }}>
                  <img src="/dark/assets/imgs/brands/03.webp" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100" style={{ width: "150px" }}>
                  <img src="/dark/assets/imgs/brands/04.webp" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100" style={{ width: "100px" }}>
                  <img src="/dark/assets/imgs/brands/05.webp" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100" style={{ width: "150px" }}>
                  <img src="/dark/assets/imgs/brands/06.webp" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100" style={{ width: "150px" }}>
                  <img src="/dark/assets/imgs/brands/07.webp" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100" style={{ width: "150px" }}>
                  <img src="/dark/assets/imgs/brands/08.webp" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100" style={{ width: "150px" }}>
                  <img src="/dark/assets/imgs/brands/09.webp" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100" style={{ width: "150px" }}>
                  <img src="/dark/assets/imgs/brands/10.webp" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100" style={{ width: "150px" }}>
                  <img src="/dark/assets/imgs/brands/11.webp" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100" style={{ width: "150px" }}>
                  <img src="/dark/assets/imgs/brands/12.webp" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100" style={{ width: "150px" }}>
                  <img src="/dark/assets/imgs/brands/13.webp" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100" style={{ width: "150px" }}>
                  <img src="/dark/assets/imgs/brands/14.webp" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100" style={{ width: "150px" }}>
                  <img src="/dark/assets/imgs/brands/15.webp" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100" style={{ width: "150px" }}>
                  <img src="/dark/assets/imgs/brands/16.webp" alt="" />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default Clients;
