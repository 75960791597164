import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Challenge() {
  return (
    <section className="section-padding bord-thin-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <h1 className="mb-50">
              The
              <br /> Process
            </h1>
          </div>
          <div className="col-lg-7">
            <div className="text">
              <h6 className="line-height-40">
                Orginal site was built on a wordpress. The site was slow and not
                user friendly. The site was not mobile responsive. The site was
                not SEO optimized. However, as wordpress was still prefered as
                the cms of choice, the site was rebuilt on wordpress with a new
                design.
              </h6>
            </div>

            <div className="accordion bord mt-50">
              <Accordion
                defaultExpanded={false}
                sx={{
                  backgroundColor: "#040404",
                  color: "white",
                  borderBottom: "1px solid #0d122e",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "yellowgreen" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">
                    Understanding Objective of the website
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Different company have different objective for their
                    website. Some company use their website to showcase their
                    product, some use it to generate leads, some use it to sell
                    their product. Understanding the objective of the website is
                    the first step in the process of redesigning a website.{" "}
                    <br />
                    <br /> Usually, the objective of the website is to generate
                    leads. However, business model is unique and so is the
                    objective of the website. But, in most cases, after serving
                    several clients, they're not entirely sure themselves. This
                    is when having wireframes and user flow comes in handy.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  backgroundColor: "#040404",
                  color: "white",
                  borderBottom: "1px solid #0d122e",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "yellowgreen" }} />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography variant="h6">
                    Design and Development, SEO
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The design and development of the website is the most
                    important part of the process. The design of the website
                    should be user friendly and should be easy to navigate. The
                    website should be mobile responsive and should be SEO
                    optimized. The website should be fast and should load
                    quickly. The website should be secure and should be
                    protected from hackers. <br />
                    <br />
                    The website should be easy to update and should be easy to
                    maintain. The website should be easy to scale and should be
                    able to handle large amount of traffic. The website should
                    be easy to integrate with other systems and should be able
                    to communicate with other systems. The website should be
                    easy to use and should be easy to understand. The website
                    should be easy to use and should be easy to navigate.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  backgroundColor: "#040404",
                  color: "white",
                  borderBottom: "1px solid #0d122e",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "yellowgreen" }} />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography variant="h6">
                    Web Infrastructure, Security, Analytics
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Setting up the web infrastructure is the next step in the
                    process. The website should be hosted on a secure server and
                    should be protected from hackers. The website should be
                    backed up regularly and should be able to recover from any
                    disaster. The website should be monitored regularly and
                    should be able to handle large amount of traffic. The
                    website should be able to scale and should be able to handle
                    large amount of traffic. The website should be able to
                    integrate with other systems and should be able to
                    communicate with other systems. The website should be able
                    to track the performance of the website and should be able
                    to analyze the data. The website should be able to generate
                    reports and should be able to provide insights into the
                    performance of the website. The website should be able to
                    generate reports and should be able to provide insights into
                    the performance of the website.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  backgroundColor: "#040404",
                  color: "white",
                  borderBottom: "1px solid #0d122e",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "yellowgreen" }} />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography variant="h6">
                    Tools for monitoring and tracking
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    SEO tools like Google Analytics, Google Search Console,
                    SEMRush, Ahrefs, Moz, etc. are used to monitor and track the
                    performance of the website. These tools help in tracking the
                    performance of the website and provide insights into the
                    performance of the website. These tools help in generating
                    reports and provide insights into the performance of the
                    website. These tools help in tracking the performance of the
                    website and provide insights into the performance of the
                    website. These tools help in generating reports and provide
                    insights into the performance of the website. These tools
                    help in tracking the performance of the website and provide
                    insights into the performance of the website. These tools
                    help in generating reports and provide insights into the
                    performance of the website.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
        <div className="row mt-120">
          <div className="col-lg-12">
            <p className="text-center">
              old site vs version 2.0
            </p>
          </div>
          <div className="col-md-6 mt-4">
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/web/rsgui/before01.png"
                alt=""
              />
            </div>
          </div>
          <div className="col-md-6 mt-4">
            <div className="img">
              <img
                src="/dark/assets/imgs/works/projects/web/rsgui/after01.webp"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Challenge;
