"use client";
import React, { useLayoutEffect } from "react";
import { gsap } from "gsap";
import { Navigation, Pagination, Parallax } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Lottie from "lottie-react";
import SlideRightLottie from "../../../animation/Slide-Lottie2.json";

function Header() {
  useLayoutEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo(".header-project2", { y: 200 }, { y: 0 }, "+=0.5");
    tl.fromTo(
      ".header-project2 .container-xl",
      { opacity: 0, translateY: 40 },
      { opacity: 1, translateY: 0 },
      "-=0"
    );

    // Cleanup function
    return () => tl.kill();
  }, []);

  return (
    <section
      className="header header-project2 section-padding pb-0"
      id="topofpage"
    >
      <div className="container-xl mt-80 mb-80">
        <div className="row align-items-end">
          <div className="col-lg-6">
            <div className="full-width mb-30">
              <h6 className="mb-0 main-color">Website Design & Development</h6>
              <h2 className="mb-10">RealSeriousGames.com</h2>
              <p>
                Following the success of the rebranding project, I extended my
                services design and develop their new website. The website was
                designed to reflect the new brand identity and showcase the
                company's work in the field of virtual reality and educational
                gaming. Since this project began in 2020, the website has gone
                through several iterations and updates base on the analytical
                data and studies to improve performance and experience.
                Integrating the grand scale of the digital eco-system is noted
                in a separate journal entry.
              </p>
              <h6 className="mt-30 main-color">Live Url:</h6>
              <a
                href="https://realseriousgames.com"
                target="_blank"
                rel="noreferrer"
                style={{ letterSpacing: "1px" }}
              >
                realseriousgames.com
              </a>
              <h6 className="mt-30 main-color">Skills provided:</h6>
             <p> Website UI UX Design, Post-Production and motion-graphics assets, Frontend Backend, 3D modeling, SEO & Marketing knowledge.</p>
              <h6 className="mt-30 main-color">Tools:</h6>
             <p>VS code, AWS EC2, Centos Infra, Figma, Illustrator, Photoshop Premiere Pro & After Effects, Blender, Lottie</p>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <div className="info">
              <div className="row">
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Category :</span>
                    <h6>Website Design & Development | UI & UX</h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Client :</span>
                    <h6>Real Serious Games</h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Start Date :</span>
                    <h6>June 2020</h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item">
                    <span className="opacity-8 mb-5 fz-12">Company:</span>
                    <h6>
                      Real Serious Games <br />
                      <p className=" fz-12 fw-500">
                        - Senior Cross-Discipline Designer
                      </p>
                    </h6>
                  </div>
                </div>
                <div className="col-md-12 mt-30">
                  <div className="item">
                    <span className="mb-5">Deliverables:</span>
                    <p>
                      Website Design, Website Development (version-1: wordpress,
                      version-2: native php framework, version-3: nextjs), SEO,
                      Analytics, Performance Optimization, User Experience, User
                      Interface, Content Strategy, Content Management, Social
                      Media Integration, API Integration, CRM Integration, CMS
                      Integration, Blog Integration, Newsletter Integration,
                      Chat Integration, Forum Integration, Support Integration,
                      Hosting Integration, Domain Integration, SSL Integration,
                      Security Integration.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid rest mb-30">
        <div
          className="img"
          style={{
            display: "flex",
            justifyContent: "center",
            background: "rgb(21 28 49)",
          }}
        >
          <div className="video-frame">
            <video
              id="tab-5"
              className="bg-img tab-img"
              src="/dark/assets/imgs/video/rsgweb-1460x1290.webm"
              autoPlay
              loop
              muted
              width="100%"
              style={{ maxWidth: "1460px", maxHeight: "1290px" }}
            />
          </div>
        </div>
        <div
          className="container-fluid rest"
          style={{ background: "rgb(21 28 49)" }}
        >
          <div className="col-md-12">
            <div className="text-center mb-30">
              <p style={{ paddingBottom: "2rem" }}>Current version 2024</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid rest">
        <div className="col-md-12">
          <div className="text-center mt-120 mb-15">
            <p>Research & Wireframes</p>
          </div>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Parallax]}
          //navigation
          pagination={false}
          parallax
          spaceBetween={0}
          centeredSlides={true}
          loop={true}
          className="project2-swiper"
        >
          <SwiperSlide className="slide-maxh1" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1"
                src="/dark/assets/imgs/works/projects/web/rsgui/01.webp"
                alt="Slide 1"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-maxh1" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1"
                src="/dark/assets/imgs/works/projects/web/rsgui/02.webp"
                alt="Slide 2"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-maxh1" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1"
                src="/dark/assets/imgs/works/projects/web/rsgui/04.webp"
                alt="Slide 3"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-maxh1" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1"
                src="/dark/assets/imgs/works/projects/web/rsgui/05.webp"
                alt="Slide 4"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-maxh1" style={{ marginRight: "0" }}>
            <div className="img img-maxh1-wrapper">
              <img
                className="slide-img-maxh1"
                src="/dark/assets/imgs/works/projects/web/rsgui/06.webp"
                alt="Slide 4"
              />
            </div>
          </SwiperSlide>
        </Swiper>
        <Lottie
          id="slide-lottie-img2"
          animationData={SlideRightLottie}
          loop={true}
          style={{ marginTop: "-25px" }}
        />
      </div>
    </section>
  );
}

export default Header;
