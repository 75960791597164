import React from "react";
import "swiper/swiper-bundle.css";

function Story() {
  return (
    <section className="section-padding">
      <div className="container-xxl">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/01.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/02.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/03.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/04.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/05.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/06.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/07.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/08.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/09.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/10.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/11.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/12.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/13.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/14.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/15.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/16.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/17.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/18.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/19.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/20.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/21.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/22.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className="img">
                  <img
                    src="/dark/assets/imgs/works/projects/indus/indus-suncorp/23.webp"
                    alt="Slide 1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="cont section-padding">
              <div className="row mb-30">
                <div className="col-md-3 sm-mb30">
                  <h6>Outcome</h6>
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                      <br />
                      <br />
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 sm-mb30">
                  <h6>Next on the list</h6>
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry.
                    </p>
                    <Link
                      to="/archives"
                      className="fz-28 fw-400 stroke next-archive-link"
                    >
                      Name_of_Next_Project
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
}

export default Story;
