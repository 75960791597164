"use client";
import React, { useLayoutEffect } from "react";
import { gsap } from "gsap";
import { Navigation, Pagination, Parallax } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Lottie from "lottie-react";
import SlideRightLottie from "../../../animation/Slide-Lottie2.json";

function Header() {
  useLayoutEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo(".header-project2", { y: 200 }, { y: 0 }, "+=0.5");
    tl.fromTo(
      ".header-project2 .container-xl",
      { opacity: 0, translateY: 40 },
      { opacity: 1, translateY: 0 },
      "-=0"
    );

    // Cleanup function
    return () => tl.kill();
  }, []);

  return (
    <section
      className="header header-project2 section-padding mb-120"
      id="topofpage"
    >
      <div className="container-xl mt-80 mb-80">
        <div className="row align-items-end">
          <div className="col-lg-6">
            <div className="full-width mb-30">
              <h6 className="mb-0 main-color">Website Design & Development</h6>
              <h2 className="mb-10">SterlingProducts.com</h2>
              <p></p>

              <h6 className="mt-30 main-color">Live url:</h6>
              <h6 className="mt-0">sterlingproducts.com</h6>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <div className="info">
              <div className="row">
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Category :</span>
                    <h6>Website Design & Development </h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Client :</span>
                    <h6>Sterling Products, Australia</h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item mb-30">
                    <span className="opacity-8 mb-5 fz-12">Year :</span>
                    <h6>2013</h6>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item">
                    <span className="opacity-8 mb-5 fz-12">Company:</span>
                    <h6>
                      Sterling Products <br />
                      <p className=" fz-12 fw-500 mt-5">
                        - Lead Designer (Multi-disciplinary)
                      </p>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid rest mb-30">
        <div
          className="img img-long-container"
          style={{
            display: "flex",
            justifyContent: "center",
            background: "#e8ebee",
            backgroundImage:
              "url(/dark/assets/imgs/works/projects/indus/indus-pamalat/T01.webp)",
            margin: "auto",
            width: "100%",
            overflowX: "scroll",

            "@media (max-width: 1400px)": {
              minWidth: "calc(100% + 100px) !important",
              justifyContent: "flex-start !important",
              overflowX: "scroll",
            },
            "@media (max-width: 768px)": {
              minWidth: "calc(100% + 100px) !important",
              justifyContent: "flex-start !important",
            },
          }}
        >
          <img
            className="img  img-long img-maxh1"
            src="/dark/assets/imgs/works/projects/indus/indus-pamalat/T01.webp"
            alt="dashten"
          />
        </div>
      </div>
      <Lottie
        id="slide-lottie-img1"
        animationData={SlideRightLottie}
        loop={true}
      /> */}
      <div className="col-md-12">
        <div className="text-center mb-10">
          <h1>{/* Heading */}</h1>
          <p>Version 2 | Wordpress CMS | Custom Theme developed with PHP</p>
        </div>
      </div>
      <Swiper
        modules={[Navigation, Pagination, Parallax]}
        //navigation
        //pagination={{ clickable: true }}
        parallax
        spaceBetween={0}
        centeredSlides={true}
        loop={true}
        className="project2-swiper"
        //style={{ overflow: "visible", maxWidth: "640px" }}
      >
        <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
          <div className="slide-video-wrapper">
            <video
              className="video"
              loading="lazy"
              src="/dark/assets/imgs/works/projects/web/proj-web-sterling-1.webm"
              alt="Slide 1"
              autoPlay
              loop
              muted
              width="100%"
              height="auto"
              //height={window.innerWidth > 768 ? "100%" : "auto"}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
          <div className="slide-video-wrapper">
            <video
              className="video"
              loading="lazy"
              src="/dark/assets/imgs/works/projects/web/proj-web-sterling-2.webm"
              alt="Slide 2"
              autoPlay
              loop
              muted
              width="100%"
              height="auto"
              //height={window.innerWidth > 768 ? "100%" : "auto"}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
          <div className="slide-video-wrapper">
            <video
              className="video"
              loading="lazy"
              src="/dark/assets/imgs/works/projects/web/proj-web-sterling-2.webm"
              alt="Slide 3"
              autoPlay
              loop
              muted
              width="100%"
              height="auto"
              //height={window.innerWidth > 768 ? "100%" : "auto"}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
          <div className="slide-video-wrapper">
            <video
              className="video"
              loading="lazy"
              src="/dark/assets/imgs/works/projects/web/proj-web-sterling-1.webm"
              alt="Slide 1"
              autoPlay
              loop
              muted
              width="100%"
              height="auto"
              //height={window.innerWidth > 768 ? "100%" : "auto"}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
          <div className="slide-video-wrapper">
            <video
              className="video"
              loading="lazy"
              src="/dark/assets/imgs/works/projects/web/proj-web-sterling-2.webm"
              alt="Slide 2"
              autoPlay
              loop
              muted
              width="100%"
              height="auto"
              //height={window.innerWidth > 768 ? "100%" : "auto"}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
          <div className="slide-video-wrapper">
            <video
              className="video"
              loading="lazy"
              src="/dark/assets/imgs/works/projects/web/proj-web-sterling-2.webm"
              alt="Slide 3"
              autoPlay
              loop
              muted
              width="100%"
              height="auto"
              //height={window.innerWidth > 768 ? "100%" : "auto"}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
          <div className="slide-video-wrapper">
            <video
              className="video"
              loading="lazy"
              src="/dark/assets/imgs/works/projects/web/proj-web-sterling-1.webm"
              alt="Slide 1"
              autoPlay
              loop
              muted
              width="100%"
              height="auto"
              //height={window.innerWidth > 768 ? "100%" : "auto"}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
          <div className="slide-video-wrapper">
            <video
              className="video"
              loading="lazy"
              src="/dark/assets/imgs/works/projects/web/proj-web-sterling-2.webm"
              alt="Slide 2"
              autoPlay
              loop
              muted
              width="100%"
              height="auto"
              //height={window.innerWidth > 768 ? "100%" : "auto"}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
          <div className="slide-video-wrapper">
            <video
              className="video"
              loading="lazy"
              src="/dark/assets/imgs/works/projects/web/proj-web-sterling-2.webm"
              alt="Slide 3"
              autoPlay
              loop
              muted
              width="100%"
              height="auto"
              //height={window.innerWidth > 768 ? "100%" : "auto"}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
          <div className="slide-video-wrapper">
            <video
              className="video"
              loading="lazy"
              src="/dark/assets/imgs/works/projects/web/proj-web-sterling-1.webm"
              alt="Slide 1"
              autoPlay
              loop
              muted
              width="100%"
              height="auto"
              //height={window.innerWidth > 768 ? "100%" : "auto"}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
          <div className="slide-video-wrapper">
            <video
              className="video"
              loading="lazy"
              src="/dark/assets/imgs/works/projects/web/proj-web-sterling-2.webm"
              alt="Slide 2"
              autoPlay
              loop
              muted
              width="100%"
              height="auto"
              //height={window.innerWidth > 768 ? "100%" : "auto"}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="slider-video" style={{ marginRight: "0" }}>
          <div className="slide-video-wrapper">
            <video
              className="video"
              loading="lazy"
              src="/dark/assets/imgs/works/projects/web/proj-web-sterling-2.webm"
              alt="Slide 3"
              autoPlay
              loop
              muted
              width="100%"
              height="auto"
              //height={window.innerWidth > 768 ? "100%" : "auto"}
            />
          </div>
        </SwiperSlide>
      </Swiper>
      <Lottie
        id="slide-lottie-img2"
        animationData={SlideRightLottie}
        loop={true}
        style={{ marginTop: "-25px" }}
      />
    </section>
  );
}

export default Header;
